
.contenedor_error {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 600px;
  width: 90%;
}
.logo {
  position: relative;
  color: white !important;
  font-size: 20px;
  font-weight: bold;
  margin: 12px;
  top: 15px;
}
.contenedor_error .error {
  font-size: 120px;
  font-weight: bold;
  margin: 0px;
  color: var(--color-botones);
}
.contenedor_error h1 {
  font-size: 2.4rem;
  margin: 0px;
}
.contenedor_error p {
  font-size: 13.5px;
}
.contenedor_error button {
  margin-top: 15px;
  padding: 15px 60px 15px 60px;
  border: none;
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
  background: var(--color-botones);
}
.contenedor_error button:hover {
  cursor: pointer;
  background: var(--color-botones-hover);
}

@media (max-width: 600px) {
  .contenedor_error h1 {
    font-size: 1.8rem;
  }
  .contenedor_error p {
    font-size: 13px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
