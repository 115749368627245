.section {
  color: white;
  margin: auto;
  margin-top: 125px;
}
section .titulo {
  font-size: 2.2rem;
  margin-bottom: 35px;
}

@supports (display: grid) {
  .main {
    color: #959595;
    grid-area: main;
    padding: 0;
    overflow: hidden;
  }

  .categorias {
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    padding-bottom: 15px;
    will-change: transform;
    user-select: none;
    cursor: pointer;
  }

  .visible-scrollbar,
  .invisible-scrollbar,
  .mostly-customized-scrollbar {
    display: block;
    width: 10em;
    overflow: auto;
    height: 2em;
  }
  .categorias.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
  .categoria {
    display: inline-block;
    background: #74747436;
    padding: 10px 50px 10px 50px;
    margin-right: 10px;
    text-align: center;
    color: white;
    transition: 0.3s;
  }
  .categoria:hover {
    transform: scale(1);
    background: white;
    color: black;
  }
  /* width */
  .categorias::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .categorias::-webkit-scrollbar:horizontal {
    height: 5px;
  }
  /* Track */
  .categorias::-webkit-scrollbar-track {
    background: #74747436;
  }

  /* Handle */
  .categorias::-webkit-scrollbar-thumb {
    background: rgb(121, 121, 121);
  }

  /* Handle on hover */
  .categorias::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.caractulas {
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
@media (min-width: 1100px) {
  .caractulas {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  }
}

@media (min-width: 1500px) {
  .caractulas {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}
@media (min-width: 1800px) {
  .caractulas {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}
@media (min-width: 2000px) {
  .caractulas {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}

.caractulas .caractula {
  margin-bottom: 25px;
}
.caractulas .caractula .type {
  position: absolute;
  background-color: var(--color-botones);
  z-index: 9;
  padding: 5px;
  margin: 7px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
.caractulas .caractula .type_serie {
  background-color: var(--color-principal);
}

.caractulas .caractula .duracion {
  position: relative;
  margin-top: -34px;
  float: right;
  margin-right: 13px;
  font-weight: 500;
  z-index: 9;
  border-radius: 20px;
  padding: 3px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 13.5px;
  background-color: rgba(0, 0, 0, 0.7);
}
.caractulas .caractula img {
  width: 100%;
}
.caractulas .caractula .titulo_caractula {
  font-size: 20px;
}
.caractulas .caractula:hover {
  cursor: pointer;
}

@media (max-width: 950px) {
  .caractula {
    display: block !important;
  }
  .caractulas {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .caractulas {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  section .titulo {
    font-size: 2rem;
  }
  .caractulas .caractula .titulo_caractula {
    font-size: 18px;
  }

  .caractulas .caractula .type {
    font-size: 11.5px;
  }

  .caractulas .caractula .duracion {
    margin-top: -30px;
    margin-right: 5px;
  }
}
