.contenedor-futbol-en-vivo{
    margin: auto;
    margin-top: 85px;
    max-width: 1300px;
    width: 96%;
}








.contenedor-futbol-en-vivo .titulo-futbol-en-vivo{
    font-size: 27px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-bottom: 35px;
}
.categorias-futbol-en-vivo{
    margin-top: 15px;
    margin-bottom: 50px;
    display: flex;
    gap: 20px;
    flex-flow: row wrap;
    
}


.partido_en_vivo i{ margin-right: 10px; font-size: 20px;}
.partido_en_vivo{
    height: 200px;
    width: 350px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;

    justify-content: center;
    padding: 20px;
    color: black;
    
}

.partido_en_vivo:hover::before{
    position: absolute;
    font-family: FontAwesome;
   content:  "\f04b";
    font-size:40px;
   background-color: rgba(0, 0, 0, 0.863);
   height: 200px;
   display: flex;   
   justify-content: center;  color: #FF0000 !important;
   align-items: center;
   width: 350px;
   z-index: 9;
   cursor: pointer;
}
.titulo_partido_en_vivo{
    font-weight: 500;
    font-size: 20px;
    margin-right: 15px;
}
.circle {
    position: relative;
    
    width: 27px;
    height: 27px;
}

.circle:before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
  
    border-radius: 45px;
    background-color: #FF0000;    
    width: 66.5px;
    height: 66.5px;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.circle:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FF0000;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.33);
   }
    80%, 100% {
        opacity: 0;
   }
}
@keyframes circle {
    0% {
        transform: scale(0.8);
   }
    50% {
        transform: scale(1);
   }
    100% {
        transform: scale(0.8);
   }
}



.titulo_categoria-futbol-en-vivo{
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;

}
.titulo_categoria-futbol-en-vivo img{
    position: relative;
    height: 22px;
    top:5px;
    margin-left: 10px;
}

.fecha__categoria-futbol-en-vivo{
    font-size: 14.5px;
}


@media (max-width: 750px){
    .partido_en_vivo{
        height: 200px;
        width: 100%;
       
            margin-bottom: 15px;
    }
    .partido_en_vivo:hover::before{
       height: 200px;
       width: 100%;
       z-index: 9;

    }

    .categorias-futbol-en-vivo{
      
        display: block;
        
        
    }
    
}

