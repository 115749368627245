.contenedor_live {
  width: 100%;
}

.jw-logo-button {
  display: none !important;
}

#my-unique-id {
  width: 100% !important;
  height: 500px !important;
}

.iframe_live {
  width: 100%;
  height: 500px;
}

.bitmovin {
  width: 100%;
  border: none;
  height: 500px;
}

@media (max-width: 750px) {
  .bitmovin {
    height: 350px !important;
  }

  #my-unique-id {
    width: 100% !important;
    height: 350px !important;
  }

  .iframe_live {
    height: 350px !important;
  }
}
