.formulario_login {
    margin: auto;
    max-width: 350px;
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
    color: white;
  }
  .formulario_login h1 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .errores_auth {
    background: rgba(255, 0, 0, 0.4);
    margin: auto;
    margin-bottom: 30px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: solid var(--color-botones) 2px;
    width: 100%;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .formulario_login .input input {
    width: 100%;
    height: 50px;
    margin-bottom: 25px;
    outline: none;
    text-indent: 40px;
    border: none;
    color: white;
    font-size: 1rem;
    background-color: rgb(29, 29, 29);
  }
  
  .formulario_login .input .icono {
    position: absolute;
    height: 50px;
    width: 38px;
  }
  .formulario_login .input .icono i {
    position: absolute;
    margin-top: 15px;
    margin-left: 12.5px;
    color: gray;
  }
  
  .formulario_login .buttom button {
    width: 100%;
    height: 50px;
    margin-bottom: 25px;
    outline: none;
    text-indent: 10px;
    border: none;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    background: var(--color-botones);
    transition: 0.3s;
  }
  .formulario_login .buttom button:hover {
    cursor: pointer;
    background: var(--color-botones-hover);
  }
  .opcion_registrarse {
    font-size: 13.5px;
    text-transform: uppercase;
  }
  .opcion_registrarse a {
    color: var(--color-botones);
    font-weight: bold;
  }
  