.section_detalles #imagen_portada_detalles_pelicula {
    position: absolute;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: -1;
  }
  
  .degradado-left {
    position: absolute;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0px;
    z-index: 1;
    background: linear-gradient(90deg, rgb(0, 0, 0), rgba(0, 0, 255, 0));
  }
  .degradado-bottom {
    background: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    position: absolute;
    position: fixed;
    top: 0px;
    width: 100%;
  }
  
  .contenidos {
    position: relative;
    z-index: 9;
    margin: auto;
    margin-top: 175px;
    max-width: 1200px;
    width: 90%;
    color: white;
  }
  
  .informacion-video {
    display: grid;
    grid-template-columns: 275px 1fr;
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .caractula_pelicula {
    height: 400px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .contenidos .btns .btn_ver_mas {
    padding: 15px 40px 15px 40px;
    font-size: 19px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .contenidos .btns .btn_ver_mas i {
    margin-right: 10px;
  }
  
  .descripcion {
    font-size: 19.5px;
    max-width: 825px;
    font-weight: 300;
  }
  .titulo {
    font-size: 40px;
    margin: 0px;
    margin-bottom: 15px;
  }
  
  .navegacion-section {
    width: 100%;
    margin-top: 50px;
    border-bottom: 1px solid #c9c9c9;
  }
  .navegacion-section ul {
    display: flex;
  
    padding: 0px;
  }
  .navegacion-section ul li {
    margin-right: 50px;
    text-transform: uppercase;
  }
  .navegacion-section ul li  {
    font-size: 20px;
    color: #c9c9c9;
    cursor: pointer;
    font-weight: bold;
  }
  
  .titulo_temporada{
    font-size: 1.3rem;
    margin: 0px;
    margin-top:15px;
  }
  @media (max-width: 950px) {
    .informacion-video {
      grid-template-columns: 1fr;
    }
    .caractula_pelicula {
      display: none;
    }
  }
  /*Detalles*/
.detalles{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 20px;
  margin-top: 20px;


  
}
.detalles .descripcion .titulo_detalle{
  margin: 0px;
  
}

.detalles .informacion{
  margin-bottom: 20px;
}
.detalles .informacion label{
  font-weight: bold;
  font-size: 18px;
}


  @media (max-width: 700px) {
    .navegacion-section ul li {
      margin-right: 15px;
    }
    .navegacion-section ul li  {
      font-size: 16px;
    }
    .titulo {
      font-size: 30px;
    }

    .detalles{

      grid-template-columns: 1fr;
      grid-gap: 20px;
    
      
    }
  }
  
