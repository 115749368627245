.contenedor_reproductor_video video {
  height: 100vh;
  width: 100%;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #e40914 !important;
  /* background: var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b3ff))); */
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}

.contenedor_reproductor_video .plyr__control--overlaid {
  background-color: #e40914;
}
.contenedor_reproductor_video .plyr__control--overlaid:hover {
  background-color: #f40612 !important;
}

.contenedor_reproductor_video .plyr--full-ui input[type="range"] {
  color: #e40914 !important;
}

.contenedor_reproductor_video .plyr__control:hover {
  background-color: #e40914;
}
