.SinResultado{
    text-align: center;
   width: 100%;
   margin-top: 85px;
    

}
.SinResultado h1{
    font-size: 23px;

}
.SinResultado .btn_{
 padding: 30px;
 font-size: 1.1rem;
 padding-top: 13px;
 padding-bottom: 13px;
 cursor: pointer;

}