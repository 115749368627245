.buscador {
    margin-top: 115px;
    text-align: center;
  }
  .buscador input {
    width: 100%;
    font-weight: 500;
    border: none;
    background: transparent;
    border-bottom: 2.6px white solid;
    height: 60px;
    color: white;
    outline: none;
    font-size: 1.5rem;
    padding-right: 30px;
  }
  .buscador button {
    position: absolute;
    color: white;
    margin-left: -30px;
    margin-top: 20px;
    font-size: 19px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
  }
  